module.exports = {
    // {{ $t(`wakuang1.us3`) }}
    wakuang1: {
        wodezhanghao: '账号',
        tuijianren: '推荐人',
        fengxiang: '分享',
        dengjijindu:'等级',
        dangqiandengji:'等级',
        haixu:'还需要',
        shengji:'升级',
        wodedaibi: '代币',
        wodeETF: 'ETF',
        zongji:'总计',
        jiazhi:'价值',
        wodekuangchi: '矿池',
        wodesuanli: '算力',
        yilingqujiangli: '已领取(U)',
        wodeshouyi: '我的收益',
        jiasushouyi: '加速收益',
        zongji: '总计(ETF)',
        jiazhi:'价值(U)',
        zongkuangchi:'矿池',
        chongzhinengliang: '充值能量',
        wodetuandui: '团队',
        wodezhitui: '直推',
        zhishuxiashu: '下属',
        dengji:'等级',
        renshu:'业绩',//我的团队人数部分改成业绩
        jine: '金额',//我的直推人数部分改成金额
        jianglimingxi: '奖励明细',
        ETFlingqu: 'ETF领取',
        shuliang: '数量',
        shijian: '时间',
        dangqianjiage: '价格',
    },
    wakuang2: {
        duihuan: '兑换',
        jishijiaoyidaibi: '即时交易代币',
        yue: '余额',
        huadianshangxian: '滑点上限',
        jiaoyi: '交易',//输入金额改成交易
    },
    guanwang1: {
        us1: '全球领先的去中心化金融平台',
        us2: '合同',
        us3: '加入联营公司',
        us4: '我们的目标',
        us5: '选择交易所交易基金作为您的金融杠杆初始资金较少',
        us6:'·ETF挖掘',
        us7:'2024年8月',
        us8: '·IM通讯',
        us9: '2024年11月',
        us10: '·ETF公链测试网',
        us11: '2025年5月',
        us12: '·ETF主网',
        us13: '2025年8月',
        us14:'·ETF生态',
        us15:'2025年12月',
        us16: 'ETF通证经济学',
        us17: '通证分配',
        us18: '总供应量',
        us19: '挖掘',
        us20:'流动性',
        us21:'业绩',
        us22: '为什么要开发ETF公链',
        us23: '为了让ETF在链上执行，这样会更公开和公平',
        us24: 'ETF可以在其他公链执行吗',
        us25: '可以实现，不过由于目前公链的机制特性，会被节点套利',
        us26: 'ETF公链和EVM公链的区别',
        us27: '修改了GAS机制，保证节点无法提前排队，让金融更公平和安全',
        us28: 'ETF一直没在公链执行的原因',
        us29: '目前EVM的排队机制，可以让节点在内网进行排队，这样可以100%套利',
        us30: '联系我们',
        hetong: '合同',
        partr1name: "2024年8月",
        partr1text: "MFT挖掘",
        partr2name: "2024年11月",
        partr2text: "IM通讯",
        partr3name: "2025年5月",
        partr3text: "MFT公链测试网",
        partr4name: "2025年8月",
        partr4text: "主网",
        partr5name: "2025年12月",
        partr5text: "MFT生态",
        tzfp: "通证分配",
        zgyl: "总供应量",
        wj: "挖掘",
        ldx: '流动性',
        part4q1: '为什么要开发MFT公链',
        part4a1: '为了让MFT在链上执行，这样会更公开和公平',
        part4q2: 'mft可以在其他公链执行吗',
        part4a2: '可以实现，不过由于目前公链的机制特性，会被节点套利',
        part4q3: 'mft公链和EVM公链的区别',
        part4a3: '修改了GAS机制，保证节点无法提前排队，让金融更公平和安全',
        part4q4: 'MFT一直没在公链执行的原因',
        part4a4: '目前EVM的排队机制，可以让节点在内网进行排队，这样可以100%套利',
        whjyjj: '外汇交易基金',
        qqlx: '全球领先的',
        qzxh: '去中心化金融平台',
        us501: '选择交易所交易基金',
        us502: '作为您的金融杠杆初始资金较少',
        q1: '为什么要开发ETF公链',
        a1: '让ETF能够在链上执行,将更加公开公平',
        q2: 'ETF可以在其他公链上执行吗',
        a2: '可以实现的，但是由于当前公链的机制特性，将会被节点套利',
        q3: 'ETF公链和EVM公链的区别',
        a3: '修改了GAS机制，保证节点无法提前排队，让金融更公平和安全',
        q4: 'ETF未在公链执行的原因',
        a4: 'EVM目前的排队机制允许节点在内网进行排队，可以100%套利',
    },
    guanwang2: {
        us111: '主页',
        us222: '白皮书',
        us333: '基金会',
        us444: '宣发',
        us555: '采矿',
        us666: '联系我们',
        us777: '复制成功',
    },
    chaojijiedian: {
        chaojijiedianhehuorenzhaomu: '超级节点合伙人招募',
        wodedizhi: '我的地址',
        wodezhanghao: '我的账号',
        shouquandizhi: '授权地址',
        shifoushichaojijiedian: '是否是超级节点',
        shichaojijiedian:'是超级节点',
        bushichaojijiedian:'不是超级节点',
        dianjijiaru: '点击加入',
    },
    xuanfa: {
        baocuntupian:'保存图片',
        baocunchenggong:'保存成功',
    },
    tishi: {
        us888:'设置成功',
        us999:'请连接钱包',
        us1111:'复制成功',
        us2222:'取消操作',
        us3333:'无法与自己绑定！',
        us4444:'请先连接钱包',
        us5555:'请输入正确的地址',
        us6666:'已成功加入！',
        us7777:'当前地址已经是超级节点!',
        us8888:'请输入有效的地址',
        us9999:'兑换成功',
        us11111:'绑定中...',
        us22222:'授权中...',
        us33333:'支付中...',
    },
    yilou: {
        us44444:'我的收益（冻结）',
        us55555:'升级中...',
        us66666:'领取中...',
        us77777:'正在加入...',
        us88888:'兑换中...',
        us99999:'确认',
        us111111:'取消',        
    },
    head: {
        connect: '连接钱包'
    }
}